var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h2", [
              _vm._v(
                _vm._s(
                  _vm.$t("modals.createPersonalizationSet.microsoft.step4")
                )
              )
            ])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "modals.createPersonalizationSet.microsoft.marketingList"
                    )
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", disabled: true },
                    model: {
                      value: _vm.marketingList,
                      callback: function($$v) {
                        _vm.marketingList = $$v
                      },
                      expression: "marketingList"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    label: _vm.$t(
                      "modals.createPersonalizationSet.microsoft.contactFields"
                    )
                  }
                },
                [
                  _c("b-form-input", {
                    attrs: { type: "text", disabled: true },
                    model: {
                      value: _vm.contactFields,
                      callback: function($$v) {
                        _vm.contactFields = $$v
                      },
                      expression: "contactFields"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { staticClass: "mr-auto", attrs: { cols: "auto" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "green-button border-0 mt-2",
                  attrs: { size: "lg" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("prev-step")
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("modals.createPersonalizationSet.back")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }