<template>
  <div>
    <b-row>
      <b-col cols="12">
        <h2>{{ $t('modals.createPersonalizationSet.microsoft.step4') }}</h2>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('modals.createPersonalizationSet.microsoft.marketingList')"
        >
          <b-form-input
            type="text"
            :disabled="true"
            v-model="marketingList"
          ></b-form-input>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          :label="$t('modals.createPersonalizationSet.microsoft.contactFields')"
        >
          <b-form-input
            type="text"
            :disabled="true"
            v-model="contactFields"
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto" class="mr-auto">
        <b-button
          size="lg"
          @click="$emit('prev-step')"
          class="green-button border-0 mt-2"
        >
          {{ $t('modals.createPersonalizationSet.back') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PersonalizationMicrosoftStepThree',
  props: {
    marketingListProp: {
      type: Array,
    },
    marketingListIdProp: {
      type: String,
    },
    contactFieldsProp: {
      type: Array,
    },
  },
  computed: {
    marketingList() {
      const marketingListObject = this.marketingListProp
        .find((item) => item.value === this.marketingListIdProp);
      if (marketingListObject) {
        return marketingListObject.text;
      }
      return 'no marketing list selected';
    },
    contactFields() {
      if (this.contactFieldsProp && this.contactFieldsProp.length > 0) {
        return this.contactFieldsProp.join(', ');
      }
      return 'no contact fields selected';
    },
  },
};
</script>

<style scoped lang="scss">
.modal-body h4 {
  font-size: 15px;
  font-weight: bold;
  color: #44484C;
}
h2 {
  color: #bbb;
}

ul {
  padding-left: 0;
  list-style: none;

  li {
    color: #44484C;

    /deep/ svg {
      color: #B9B9B9;

      &.active {
        color: #53DC86;
      }
    }

    &.past-step {
      cursor: pointer;
    }
  }
}

div.step-1 {
  min-height: 120px;
}

.grey-line {
  height: 30px;
  border-left: 1px solid #B9B9B9;
  margin-left: 6px;
}

.show-url-icon svg {
  font-size: 60px;
}

.show-url {
  font-size: 1.1rem;
}

.loading-spinner {
  min-height: 400px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.background-spinner {
  position: absolute;
  inset: 0px;
  opacity: 0.85;
  backdrop-filter: blur(2px);
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
}
.disabled {
  cursor: not-allowed;
}
</style>
